import { createSlice, PayloadAction, createAsyncThunk, Dispatch } from "@reduxjs/toolkit";
import { RootState } from "../../reduxStore";

const actionTypePrefix = "device";
export const DeviceStatusUpdated = "UPDATED";
export const DeviceStatusUpdating = "UPDATING";
export type DeviceStatus = typeof DeviceStatusUpdated | typeof DeviceStatusUpdating;

interface Device {
    status: DeviceStatus;
    recentlyUpdated: boolean;
    timeout: NodeJS.Timeout | null;
}

interface Devices {
    [key: string]: Device | undefined;
}

interface HearingInstrument {
    id: string;
    isProgramming: boolean;
}

const slice = createSlice({
    name: actionTypePrefix,
    initialState: {} as Devices,
    reducers: {
        setHIState: {
            reducer: (state, action: PayloadAction<{ deviceId: string; status: DeviceStatus }>) => {
                state[action.payload.deviceId] = {
                    timeout: null,
                    ...state[action.payload.deviceId],
                    status: action.payload.status,
                    recentlyUpdated: false
                };
            },
            prepare: (deviceId: string, status: DeviceStatus) => ({
                payload: { deviceId, status },
            }),
        },
        unloadDevice: (state, action: PayloadAction<string>) => {
            delete state[action.payload];
        },
        setRecentlyUpdated: {
            reducer: (
                state,
                action: PayloadAction<{ deviceId: string; recentlyUpdated: boolean; timeout: NodeJS.Timeout | null }>
            ) => {
                state[action.payload.deviceId] = {
                    status: "UPDATED",
                    ...state[action.payload.deviceId],
                    recentlyUpdated: action.payload.recentlyUpdated,
                    timeout: action.payload.timeout,
                };
            },
            prepare: (deviceId: string, recentlyUpdated: boolean, timeout: NodeJS.Timeout | null) => ({
                payload: { deviceId, recentlyUpdated, timeout },
            }),
        },
    },
});

export const handleDeviceUpdates = createAsyncThunk<
    void,
    HearingInstrument,
    {
        dispatch: Dispatch;
        state: RootState;
    }
>(actionTypePrefix + "/handleDeviceUpdates", (hearingInstrument, thunkApi) => {
    if (hearingInstrument.isProgramming) {
        thunkApi.dispatch(slice.actions.setHIState(hearingInstrument.id, "UPDATING"));
    } else {
        thunkApi.dispatch(slice.actions.setHIState(hearingInstrument.id, "UPDATED"));
        const { devices } = thunkApi.getState();
        clearTimeout(devices[hearingInstrument.id]?.timeout ?? undefined);
        const timeout = setTimeout(() => {
            thunkApi.dispatch(setRecentlyUpdated(hearingInstrument.id, false, null));
        }, 2000);

        thunkApi.dispatch(setRecentlyUpdated(hearingInstrument.id, true, timeout));

    }
    return Promise.resolve();
});

export const { setHIState, unloadDevice, setRecentlyUpdated } = slice.actions;
export default slice.reducer;
